import './App.css';

function Success() {
  return (
      <div className="App-header">
        <p>
          Thanks for your interest, we hope to reach out in an email soon!
        </p>
      </div>
  );
}

export default Success;
