import logo from './mini-small.png';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Success from './Success';
import Button from '@mui/base/Button';
import Header from './Header';

function App() {
  return (
    <div className="App-menu">
        <Header/>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/success" element={<Success />} />
    </Routes>
    </div>
  );
}

export default App;
