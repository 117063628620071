import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

const theme = createTheme({
  palette: {
    neutral: {
      main: '#000000',
      contrastText: '#fff',
    },
  },
});

export default function CustomColor({...buttonProps}) {
  return (
    <ThemeProvider theme={theme}>
      <Button type="submit" style={{borderRadius: '0px 12px 12px 0px', padding: '8px 16px 8px 16px'}}color="neutral" variant="contained" endIcon={<SendIcon />} {...buttonProps}>
        Send
      </Button>
    </ThemeProvider>
  );
}
