import logo from './mini-small.png';
import './App.css';
import MyComponent from './MyComponent';
import Button from '@mui/base/Button';
import Header from './Header';

function App() {
  return (
      <div className="App-header">
      <p>
        Thanks for your interest, miniproctor has been paused for now. Please come back soon!
      </p>
        <img src={logo} className="App-logo" alt="logo" />
        <MyComponent/>
      </div>
  );
}

export default App;
