import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import CustomColor from './CustomInput';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import './MyComponent.css';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const MyComponent = () => {
  const [age, setAge] = React.useState('');
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setAge(event.target.value);
    navigate('/success');
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Box sx={{ minWidth: 120 }}>
            <FormControl sx={{ m: 1, minWidth: 200 }} disabled>
              <InputLabel id="demo-simple-select-label">Select Exam</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Select Exam"
                onChange={handleChange}
              >
                <MenuItem value="SAT">SAT</MenuItem>
                <MenuItem value="ACT">ACT</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item style={{ paddingLeft: 0 }}></Grid>
      </Grid>
    </div>
  );
};

export default MyComponent;
